import React, { useMemo } from "react";

import Moment from "moment";

import { makeStyles } from "@material-ui/core/styles";

import { Paper, Grid, Typography } from "@material-ui/core";

import ListLink from "../../../../components/ListLink";
import ListItem from "../../../../components/ListItem";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  buttonGroup: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
}));

export default function OrderList({ orders, type = "Orders" }) {
  const classes = useStyles();

  let list = useMemo(() => {
    return orders;
  }, [orders]);

  return (
    <Grid item xs={12}>
      <Paper className={classes.paper}>
        {list.length > 0 ? (
          <ListLink>
            {orders.map((order, i) => {
              let url =
                type == "Orders"
                  ? "/Orders/View/Order/"
                  : "/Orders/View/Draft/";

              return (
                <ListItem key={i} to={url + order._id}>
                  <Grid container spacing={3} alignItems="center">
                    <Grid item xs={4} align="left">
                      {order.customer && <p>{order.customer.name}</p>}
                    </Grid>
                    <Grid item xs={4} align="right">
                      <p>{Moment(order.date).format("hh:mm A MM/DD/YY")}</p>
                    </Grid>
                  </Grid>
                </ListItem>
              );
            })}
          </ListLink>
        ) : (
          <Typography>No {type} Found</Typography>
        )}
      </Paper>
    </Grid>
  );
}
