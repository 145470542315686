import React, { useContext, useEffect, useState } from "react";
import { StoreContext } from "../../context/StoreContext";
import { useAlert } from "../../customHooks";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";

import { Paper, Grid, TextField, Button } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  margin: {
    margin: theme.spacing.unit * 2,
  },
  padding: {
    padding: theme.spacing.unit,
  },
}));

export default function LoginModal() {
  const { state } = useContext(StoreContext);

  const { createAlert } = useAlert();
  const history = useHistory();
  const classes = useStyles();
  const { makeRequest, setUser, setLogin } = useContext(StoreContext);

  const [data, setData] = useState({
    username: undefined,
    password: "",
  });

  const updateField = (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
  };

  const submit = () => {
    let payload = { name: data.username, password: data.password };
    console.log("Submitting");
    console.log(payload);
    makeRequest("post", "auth", "/auth/", payload)
      .then((res) => {
        console.log("Sucess");
        setUser(res.data);
        setLogin(false);
      })
      .catch((error) => {
        createAlert(error);
        console.log(error);
      });
  };

  return (
    <React.Fragment>
      {state.login && (
        <div className="Loading">
          <div className="Text">
            <Paper className={classes.padding}>
              <div className={classes.margin}>
                <Grid container spacing={3} alignItems="flex-end">
                  <Grid item xs={12}>
                    <TextField
                      id="username"
                      label="Username"
                      type="username"
                      value={data.username}
                      name="username"
                      onChange={updateField}
                      fullWidth
                      required
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      id="password"
                      label="Password"
                      type="password"
                      value={data.password}
                      name="password"
                      onChange={updateField}
                      fullWidth
                      required
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    style={{ display: "flex", justifyContent: "center" }}
                  >
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={submit}
                    >
                      Login
                    </Button>
                  </Grid>
                </Grid>
              </div>
            </Paper>
          </div>
          <div className="Background"></div>
        </div>
      )}
    </React.Fragment>
  );
}
