import React, { useState, useEffect, useContext } from "react";
import { useAlert } from "../../customHooks";
import { Link } from "react-router-dom";

import Loading from "../../components/Loading";

import { makeStyles } from "@material-ui/core/styles";
import { Grid, Button, Divider } from "@material-ui/core";

import { StoreContext } from "../../context/StoreContext";
import OrderList from "./components/OrderList";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  buttonGroup: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
}));

export default function QuoteHomePage() {
  const { createAlert } = useAlert();
  const { makeRequest } = useContext(StoreContext);
  const classes = useStyles();

  const [orders, setOrders] = useState([]);
  const [search, setSearch] = useState(false);
  const [loading, setLoading] = useState(false);
  const [startDate, setStartDate] = useState(Date.now());
  const [endDate, setEndDate] = useState(Date.now());

  // Submit Order to Database
  const searchQuotes = () => {
    setLoading(true);
    makeRequest("post", "api", "/draftorders/search", { startDate, endDate })
      .then((res) => {
        console.log(res);
        setSearch(true);
        setLoading(false);
        setOrders(res.data);
      })
      .catch((error) => {
        createAlert(error);
      });
  };

  return (
    <Grid container spacing={3}>
      <Grid item className="flex flexBaseline" xs={12}>
        <h2 className="flexSpacer">Quotes</h2>
        <div className={classes.buttonGroup}>
          <Button
            variant="contained"
            color="primary"
            component={Link}
            to={"/Orders/Form"}
          >
            New Quote
          </Button>
        </div>
      </Grid>
      <Grid item xs={12}>
        <Divider />
      </Grid>
      <Grid
        item
        xs={12}
        spacing={3}
        style={{ display: "flex", gap: "1em", alignItems: "end" }}
      >
        <div>
          <p>Start</p>
          <input
            type="date"
            value={moment(startDate).format("YYYY-MM-DD")}
            onChange={(e) => {
              setStartDate(e.target.value);
            }}
          />
        </div>
        <div>
          <p>End</p>
          <input
            type="date"
            value={moment(endDate).format("YYYY-MM-DD")}
            onChange={(e) => {
              setEndDate(e.target.value);
            }}
          />
        </div>
        <Button variant="contained" color="primary" onClick={searchQuotes}>
          Search
        </Button>
      </Grid>
      {search && (
        <React.Fragment>
          {loading ? (
            <Grid item xs={12}>
              <h1>Loading...</h1>
            </Grid>
          ) : (
            <OrderList orders={orders} type="Quotes" />
          )}
        </React.Fragment>
      )}
    </Grid>
  );
}
