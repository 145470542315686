/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useContext, useEffect } from 'react';
import { useAlert } from '../../customHooks';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { Paper, Grid, TextField, Button } from '@material-ui/core';

// Components
import CenterCard from '../../components/CenterCard';
import MultiStep from '../../components/MultiStep';

// Context
import { StoreContext } from '../../context/StoreContext';

const useStyles = makeStyles((theme) => ({
  margin: {
    margin: theme.spacing.unit * 2
  },
  padding: {
    padding: theme.spacing.unit
  }
}));

export default function Login() {
  const { createAlert } = useAlert();
  const history = useHistory();
  const classes = useStyles();
  const { makeRequest, setUser } = useContext(StoreContext);

  const [data, setData] = useState({
    username: undefined,
    password: ''
  });

  const updateField = (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.value
    });
  };

  const submit = () => {
    let payload = { name: data.username, password: data.password };
    makeRequest('post', 'auth', '/auth/', payload)
      .then((res) => {
        setUser(res.data);
        history.push('/');
      })
      .catch((error) => {
        createAlert(error);
      });
  };

  return (
    <CenterCard>
      <Paper className={classes.padding}>
        <div className={classes.margin}>
          <Grid container spacing={3} alignItems="flex-end">
            <Grid item xs={12}>
              <TextField
                id="username"
                label="Username"
                type="username"
                value={data.username}
                name="username"
                onChange={updateField}
                fullWidth
                required
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="password"
                label="Password"
                type="password"
                value={data.password}
                name="password"
                onChange={updateField}
                fullWidth
                required
              />
            </Grid>
            <Grid
              item
              xs={12}
              style={{ display: 'flex', justifyContent: 'center' }}
            >
              <Button variant="contained" color="primary" onClick={submit}>
                Login
              </Button>
            </Grid>
          </Grid>
        </div>
      </Paper>
    </CenterCard>
  );
}
